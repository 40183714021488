// @flow
import { type JSSThemeDefaultHeaderMenuType } from '../JSSThemeDefaultHeaderMenu';

export const JSSThemeHeaderMenu61400: JSSThemeDefaultHeaderMenuType = (
  themeVars
) => {
  const {
    margins,
    colors: {
      colorTypes: { white, primary, background6 },
    },
  } = themeVars;
  return {
    HeaderMenuDesktop: {
      menuWrapper: {
        background: 'none',
      },
      menuItemWrapper: {
        borderRight: 'none',
      },
      menuItem: {
        color: background6,
        fontWeight: 'bold',
        padding: [0, margins.lg],
        '&:hover, &:focus': {
          color: primary,
        },
      },
      menuItemActive: {
        color: primary,
      },
    },
    HeaderMenuMobile: {
      mobileMenu: {
        background: '#1E2737',
        flexWrap: 'wrap',
      },
      menuItem: {
        borderBottom: `3px solid #1E2737`,
        '&:hover, &:focus, &:active': {
          color: white,
        },
      },
      // menuItemActive: {
      //   background: text3,
      //   borderBottom: `3px solid ${primary}`,
      // },
    },
  };
};
