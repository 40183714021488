// @flow
import { type JSSThemeDefaultAffiliatesType } from '../JSSThemeDefaultAffiliates';

export const JSSThemeAffiliates61400: JSSThemeDefaultAffiliatesType = themeVars => {
  const {
    margins,
    colors: {
      colorTypes: { accent, white, background1, background7, background3, text4 },
    },
  } = themeVars;

  return {
    text: {
      margin: margins.md,
    },
    affiliatesHeading: {
      background: background7,
    },
    affiliateHeader: {
      headerRoot: {
        background: background7,
      },
      logo: {
        height: '30px',
        marginTop: '6px',
        '@media (pointer: coarse)': {
          marginTop: '10px',
          height: '18px',
        },
      },
      burgerOverlay: {
        background: background1,
      },
      SignupRoot: {
        color: white,
        '&:hover': {
          opacity: '1',
        },
      },
      loginBar: {
        '@media (pointer: coarse)': {
          background: background1,
        },
      },
      loginBarRoot: {
        background: background3,
      },
      topMenuItemActive: {
        color: `${accent} !important`,
      },
      reasonsIcon: {
        fill: background3,
      },
      topMenuItem: {
        color: white,
        textTransform: 'uppercase',
        '&:hover': {
          color: accent,
        },
      },
    },
    affiliatesHome: {
      topBannerRoot: {
        background: background7,
      },
      reasonsItem: {
        color: background7,
      },
      step: {
        boxShadow: '0px 8px 21px 14px rgb(52 118 200 / 15%)',
      },
      topBannerButton: {
        backgroundColor: accent,
        background: accent,
        boxShadow: 'none',
        textShadow: 'none',
        '&:hover': {
          background: text4,
          backgroundColor: text4,
          boxShadow: 'none',
          textShadow: 'none',
          color: white,
        },
      },
      productsButton: {
        backgroundColor: accent,
        background: accent,
        boxShadow: 'none',
        textShadow: 'none',
        '&:hover': {
          background: text4,
          backgroundColor: text4,
          boxShadow: 'none',
          textShadow: 'none',
          color: white,
        },
      },
      affiliateRoot: {
        background: background1,
      },
      productsRoot: {
        background: background1,
      },
      topBannerHeader: {
        fontSize: '48px',
        maxWidth: '450px',
        fontWeight: '800',
        lineHeight: '62px',
        '@media (pointer: coarse)': {
          fontSize: '24px',
          lineHeight: '34px',
        },
      },
    },
    affiliatesCommission: {
      commissionTableCell: {
        background: white,
      },
    },
    affiliatesSignup: {
      signupImg: {
        display: 'block',
        margin: '0 auto',
        width: '100%',
        maxWidth: '1216px',
      },
      signupIframeWrapper: {
        flexDirection: 'column',
      },
      signupIframe: {
        margin: '-20px auto 0',
      },
    },
    affiliatesProducts: {
      productsImgWrapper: {
        display: 'flex',
      },
      productsButton: {
        backgroundColor: accent,
        background: accent,
        boxShadow: 'none',
        textShadow: 'none',
        '&:hover': {
          background: text4,
          backgroundColor: text4,
          boxShadow: 'none',
          textShadow: 'none',
          color: white,
        },
      },
      productsImg: {
        width: '200px',
        objectFit: 'contain',
        alignSelf: 'flex-start',
        '@media (pointer: coarse)': {
          display: 'none',
        },
      },
      productsImgText: {
        padding: [0, 0, 0, '60px'],
        maxWidth: '700px',
        '@media (pointer: coarse)': {
          padding: 0,
        },
      },
    },
    affiliatesContacts: {
      contactsImgWrapper: {
        display: 'flex',
      },
      contactsImg: {
        width: '200px',
        '@media (pointer: coarse)': {
          display: 'none',
        },
      },
      contactsMail: {
        '&:hover': {
          color: accent,
        },
      },
      contactsImgText: {
        padding: ['35px', 0, 0, '60px'],
        maxWidth: '500px',
        '@media (pointer: coarse)': {
          padding: 0,
        },
      },
      bonusText: {
        color: 'rgba(4,26,61, .6)',
        fontSize: '16px',
        padding: [0, 0, '30px'],
        lineHeight: '26px',
        maxWidth: '670px',
      },
      bonusLink: {
        fontSize: '24px',
        color: '#19191a',
        textDecoration: 'none',
      },
      bonusList: {
        opacity: '.6',
        fontSize: '16px',
      },
      bonusListItem: {
        marginBottom: '28px',
        color: 'rgba(4,26,61, 1)',
        fontSize: '16px',
        maxWidth: '670px',
        lineHeight: '26px',
      },
      bonusContent: {
        margin: '0 auto',
        padding: ['100px', '20px'],
        maxWidth: '1216px',
        color: 'rgba(4,26,61, .6)',
      },
      bonusTable: {
        border: '1px solid rgba(4,26,61, .1)',
        color: 'rgba(4,26,61, .6)',
        padding: [0, 0, margins.md],
        borderRadius: 20,
        overflow: 'hidden',
        marginBottom: '50px',
      },
      bonusTableHeading: {
        color: 'rgba(4,26,61, .6)',
        fontWeight: 'bold',
        background: 'rgba(4,26,61, .1)',
        padding: margins.lg,
      },
      bonusTableCell: {
        padding: [margins.lg, margins.lg, margins.md],
      },
    },
    affiliatesFooter: {
      footerRoot: {
        background: background1,
      },
      productsTerms: {
        color: white,
        '&:hover': {
          color: accent,
        },
      },
    },
  };
};
