// @flow
import { type JSSThemeDefaultHeaderMobileType } from '../JSSThemeDefaultHeaderMobile';

export const JSSThemeHeaderMobile61400: JSSThemeDefaultHeaderMobileType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { background1, background6 },
    },
  } = themeVars;
  return {
    HeaderMobile: {
      languageSelectWrap: {
        display: 'flex',
        alignItems: 'center',
      },
      headerContainer: {
        background: background1,
      },
      menuContainer: {
        color: background6,
        backgroundColor: '#1E2737',
      },
      logoImg: {
        maxWidth: '100px',
        width: 'fit-content',
      },
    },
  };
};
