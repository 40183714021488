// @flow
import {
  MENU_ITEM_CASINO,
  MENU_ITEM_LIVE,
  MENU_ITEM_PREMATCH,
  MENU_ITEM_PROMOTIONS,
} from '../../constants';

import { HEADER_BLOG_BTN_ID } from '../../../common/elementIDs';

export const MENU_ITEMS_61400 = [
  MENU_ITEM_PREMATCH,
  MENU_ITEM_LIVE,
  MENU_ITEM_CASINO,
  MENU_ITEM_PROMOTIONS,
  {
    title: 'blog',
    to: `https://www.playonwin.com/blog`,
    id: HEADER_BLOG_BTN_ID,
    target: '_self',
  },
];

export const AMAZON_COMMON_BUCKET_URL_58 = '//cdn.tornadobet.com';
export const BACKGROUND_IMAGE_LIVE_BANNER_58_DEFAULT = `url(
  ${AMAZON_COMMON_BUCKET_URL_58}/cms/uploads/default/scoreboards/default.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_58_AMERICANFOOTBALL = `url(
  ${AMAZON_COMMON_BUCKET_URL_58}/cms/uploads/default/scoreboards/american_football.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_58_BASKETBALL = `url(
  ${AMAZON_COMMON_BUCKET_URL_58}/cms/uploads/default/scoreboards/basketball.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_58_BEACHVOLLEYBALL = `url(
  ${AMAZON_COMMON_BUCKET_URL_58}/cms/uploads/default/scoreboards/beach_volleyball.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_58_DARTS = `url(
  ${AMAZON_COMMON_BUCKET_URL_58}/cms/uploads/default/scoreboards/darts.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_58_FUTSAL = `url(
  ${AMAZON_COMMON_BUCKET_URL_58}/cms/uploads/default/scoreboards/futsal.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_58_HANDBALL = `url(
  ${AMAZON_COMMON_BUCKET_URL_58}/cms/uploads/default/scoreboards/handball.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_58_ICEHOCKEY = `url(
  ${AMAZON_COMMON_BUCKET_URL_58}/cms/uploads/default/scoreboards/hockey.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_58_FORMULA1 = `url(
  ${AMAZON_COMMON_BUCKET_URL_58}/cms/uploads/default/scoreboards/motosports.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_58_RUGBY = `url(
  ${AMAZON_COMMON_BUCKET_URL_58}/cms/uploads/default/scoreboards/rugby.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_58_FOOTBALL = `url(
  ${AMAZON_COMMON_BUCKET_URL_58}/cms/uploads/default/scoreboards/soccer.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_58_TABLETENNIS = `url(
  ${AMAZON_COMMON_BUCKET_URL_58}/cms/uploads/default/scoreboards/tabletennis.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_58_TENNIS = `url(
  ${AMAZON_COMMON_BUCKET_URL_58}/cms/uploads/default/scoreboards/tennis.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_58_VOLLEYBALL = `url(
  ${AMAZON_COMMON_BUCKET_URL_58}/cms/uploads/default/scoreboards/volleyball.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_58_BASEBALL = `url(
  ${AMAZON_COMMON_BUCKET_URL_58}/cms/uploads/default/scoreboards/baseball.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_58_ESPORTS = `url(
  ${AMAZON_COMMON_BUCKET_URL_58}/cms/uploads/default/scoreboards/esports.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_58_GREYHOUNDS = `url(
  ${AMAZON_COMMON_BUCKET_URL_58}/cms/uploads/default/greyhounds.jpg
)`;

export const BACKGROUND_IMAGE_LIVE_BANNER_58_BADMINTON =
  BACKGROUND_IMAGE_LIVE_BANNER_58_DEFAULT;
export const BACKGROUND_IMAGE_LIVE_BANNER_58_BEACHSOCCER =
  BACKGROUND_IMAGE_LIVE_BANNER_58_DEFAULT;
export const BACKGROUND_IMAGE_LIVE_BANNER_58_BOWLS =
  BACKGROUND_IMAGE_LIVE_BANNER_58_DEFAULT;
export const BACKGROUND_IMAGE_LIVE_BANNER_58_FIELDHOCKEY =
  BACKGROUND_IMAGE_LIVE_BANNER_58_DEFAULT;
export const BACKGROUND_IMAGE_LIVE_BANNER_58_SNOOKER =
  BACKGROUND_IMAGE_LIVE_BANNER_58_DEFAULT;
export const BACKGROUND_IMAGE_LIVE_BANNER_58_HORSERACING =
  BACKGROUND_IMAGE_LIVE_BANNER_58_DEFAULT;
