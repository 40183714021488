// @flow
import { type JSSThemeDefaultBannerItemsType } from '../JSSThemeDefaultBannerItems';

export const JSSThemeBannerItems61400: JSSThemeDefaultBannerItemsType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { white, text2, border1, background1, background6 },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    BannerContent: {
      bannerContentRoot: {
        color: white,
        padding: '0',
        justifyContent: 'space-between',
      },
      bannerContentFill: {
        padding: '0',
      },
      headerCasinoCarousel: {
        fontFamily: 'Montserrat',
        fontSize: 50,
        lineHeight: '70px',
        color: '#D9D9D9',
        textShadow: '0px 3.54133px 0px rgba(0, 0, 0, 0.25)',
        textTransform: 'uppercase',
        '@media screen and (max-width: 960px)': {
          fontSize: 38,
          lineHeight: '52px',
        },
        '@media screen and (max-width: 768px)': {
          fontSize: 30,
          lineHeight: '42px',
        },
      },
      descriptionRoot: {
        fontFamily: 'Montserrat',
        fontSize: 35,
        lineHeight: '40px',
        color: '#D9D9D9',
        textShadow: '0px 3.54133px 0px rgba(0, 0, 0, 0.25)',
        textTransform: 'uppercase',
        padding: 0,
        '@media screen and (max-width: 960px)': {
          fontSize: 27,
          lineHeight: '31px',
        },
        '@media screen and (max-width: 768px)': {
          fontSize: 21,
          lineHeight: '24px',
        },
      },
      bannerContentCasinoCarousel: {
        padding: '50px 20px 20px 89px',
        maxWidth: '50%',
        '@media screen and (max-width: 960px)': {
          maxWidth: '80%',
          padding: '40px 20px 20px 68px',
        },
        '@media screen and (max-width: 768px)': {
          maxWidth: '100%',
          padding: '20px 20px 20px 50px',
        },
        '@media screen and (max-width: 360px)': {
          maxWidth: '100%',
          padding: '20px 25px 20px 25px',
        },
      },
      bannerContentSportsbookCarousel: {
        padding: '56px 15px 15px 80px',
        justifyContent: 'start',
      },
      bannerContentSportsbookSide: {
        padding: [margins.xl, margins.lg],
      },
      bannerContentCasinoSide: {
        padding: [margins.xl, margins.lg],
      },
      bannerContentPromo: {
        padding: [margins.md],
      },
      headerSportsbookCarousel: {
        fontSize: 30,
        fontWeight: '700',
        fontFamily: 'Montserrat',
        textTransform: 'uppercase',
        color: '#D9D9D9',
        textShadow: '0px 3.54133px 0px rgba(0, 0, 0, 0.25)',
      },
      headerPromo: {
        fontSize: '30px',
        textShadow: '0px 2.16993px 0px rgba(0, 0, 0, 0.25)',
        fontWeight: 'bold',
        maxWidth: 500,
        fontFamily: 'Montserrat',
        color: '#D9D9D9',
        textTransform: 'uppercase',
      },
      descriptionSportsbookCarousel: {
        fontFamaly: 'Montserrat',
        fontWeight: 700,
        fontSize: 21,
        color: '#D9D9D9',
        textShadow: '0px 3.54133px 0px rgba(0, 0, 0, 0.25)',
        textTransform: 'uppercase',
        marginBottom: 20,
      },
      descriptionCasinoCarousel: {
        fontSize: 35,
        color: '#D9D9D9',
        padding: 0,
        '@media screen and (max-width: 960px)': {
          fontSize: 27,
          lineHeight: '30px',
        },
        '@media screen and (max-width: 768px)': {
          fontSize: 21,
          lineHeight: '24px',
        },
      },
      descriptionMobile: {
        fontSize: 27,
        lineHeight: '31px',
        '@media screen and (max-width: 768px)': {
          fontSize: 21,
          lineHeight: '24px',
        },
      },
      headerMobile: {
        fontSize: 38,
        lineHeight: '42px',
        '@media screen and (max-width: 768px)': {
          fontSize: 30,
          lineHeight: '37px',
        },
      },
      descriptionSportsbookSide: {
        fontSize: fontSizes.lg,
        padding: [margins.xs, 0],
      },
      descriptionCasinoSide: {
        fontSize: fontSizes.lg,
        padding: [margins.xs, 0],
      },
      descriptionPromo: {
        fontSize: 21,
        lineHeight: '24px',
        padding: '0',
      },
      textWrapperFill: {
        padding: '0',
      },
      buttonsWrapperSportsbookCarousel: {
        margin: [margins.md, 0, 0, 0],
      },
      buttonsWrapperSportsbookSide: {
        margin: [margins.lg, 0],
      },
      buttonsWrapperCasinoSide: {
        margin: [margins.lg, 0],
      },
      buttonsWrapperPromo: {
        margin: [margins.lg, 0, 0, 0],
      },
    },
    BannerItemSportsbookCarousel: {
      bannerItemSportsbookCarouselRoot: {
        padding: [0, margins.xs],
        marginRight: '0',
      },
      imgContainer: {
        height: '300px',
        backgroundColor: 'transparent',
        backgroundPosition: 'center right',
      },
      contentWrapper: {
        width: 'auto',
        padding: '0',
        backgroundColor: 'transparent',
      },
    },
    CarouselSlidePrematch: {
      carouselSlidePrematchRoot: {
        background: background1,
      },
      descriptionTimeWrapper: {
        color: text2,
      },
      linkWrapper: {
        margin: [margins.md, 0],
      },
      linkEvent: {
        color: border1,
      },
      descriptionTeams: {
        color: background6,
      },
    },
    SideBanner: {
      containerWithContent: {
        backgroundPosition: 'left center',
        padding: '0',
      },
    },
    BannerItemCasinoCarousel: {
      mobile: {
        height: '300px',
        '&:after': {
          background: `linear-gradient(
            to right,
            rgba(10, 31, 50, 1) 0%,
            rgba(10, 31, 50, 0) 10%,
            rgba(10, 31, 50, 0) 100%
          )`,
        },
        '@media screen and (max-width: 768px)': {
          height: 246,
        },
      },
    },
    BannerItemSportsbookCarouselMobile: {
      imgContainer: {
        padding: '150px 40px 20px 40px',
        minHeight: 250,
        maxHeight: '100%',
        justifyContent: 'end',
        backgroundPosition: 'top center',
        alignItems: 'start',
      },
      header: {
        fontFamily: 'Montserrat',
        fontSize: 22,
        lineHeight: '31px',
        color: '#D9D9D9',
        textShadow: '0px 2.17928px 0px rgba(0, 0, 0, 0.25)',
        marginBottom: 5,
        textTransform: 'uppercase',
      },
      description: {
        fontFamily: 'Montserrat',
        fontSize: 16,
        lineHeight: '19px',
        textTransform: 'uppercase',
        color: '#D9D9D9',
        textShadow: '0px 2.17928px 0px rgba(0, 0, 0, 0.25)',
      },
    },
  };
};
