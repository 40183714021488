// @flow
import { type JSSThemeDefaultHeaderType } from '../JSSThemeDefaultHeader';

export const JSSThemeHeader61400: JSSThemeDefaultHeaderType = (themeVars) => {
  const {
    margins,
    colors: {
      colorTypes: { background1 },
    },
  } = themeVars;
  return {
    HeaderDesktop: {
      root: {
        background: background1,
      },
      authContentWrap: {
        justifyContent: 'normal',
        padding: [margins.xs, 0, 0],
      },
      extraUserElementsWrapper: {
        margin: [margins.xs, 0, margins.sm],
      },
    },
    LogoBrand: {
      logoWrapper: {
        width: 250,
        padding: '7px 10px 10px',
      },
      logo: {
        maxWidth: 160,
      },
    },
  };
};
