// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme61400,
  JSSThemeVariables60500,
  getJSSThemePatterns60500,
} from './JSSTheme61400';
import { GeoblockPage61400 } from './GeoblockPage61400';
// $FlowIgnore
import '../../../styles/application-koltron.scss';

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullDesktop61400')).FullDesktop61400,
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullMobile61400')).FullMobile61400,
  }),
  JSSTheme: JSSTheme61400,
  JSSThemeVariables: JSSThemeVariables60500,
  JSSThemePatterns: getJSSThemePatterns60500,
  GeoblockPage: GeoblockPage61400,
});
