// @flow
import { type JSSThemeDefaultBetslipType } from '../JSSThemeDefaultBetslip';

export const JSSThemeBetslip: JSSThemeDefaultBetslipType = (themeVars) => {
  const {
    colors: {
      colorTypes: { background1, white, text2, primary, background6 },
      colorCombinations: { whiteBg1, whiteAccent },
    },
    margins,
    fontSizes,
    borderRadius,
  } = themeVars;
  return {
    Betslip: {
      root: {
        background: background1,
      },
    },
    EachWayButton: {
      root: {
        color: white,
      },
    },
    BetslipTotals: {
      root: {
        borderTop: 'none',
        padding: margins.md,
      },
      container: {
        paddingRight: margins.sm,
        paddingLeft: 0,
        ...whiteBg1,
      },
      summaryLabel: {
        fontSize: fontSizes.sm,
        color: text2,
      },
      summaryValue: {
        fontSize: fontSizes.xl,
        fontWeight: 'normal',
        lineHeight: `${fontSizes.lg}px`,
      },
      taxSummary: {
        color: text2,
      },
    },
    BetslipSelection: {
      common: {
        background: '#1E2737',
        margin: margins.md,
        borderRadius: '0',
      },
    },
    SingleSelectionInfo: {
      eventDescription: {
        color: background6,
        fontSize: fontSizes.sm,
      },
      remove: {
        color: text2,
      },
      marketDescription: {
        color: background6,
        fontSize: fontSizes.sm,
      },
      priceDescription: {
        color: background6,
        fontSize: fontSizes.sm,
      },
    },
    BetslipErrors: {
      list: {
        ...whiteAccent,
        borderRadius: borderRadius.md,
      },
      listItem: {
        fontSize: fontSizes.xs,
      },
    },
    BetslipInput: {
      container: {
        background: 'transparent',
        padding: [margins.xs, 0, margins.xs, 0],
      },
      label: {
        color: text2,
        fontSize: fontSizes.sm,
      },
      fieldContainer: {
        borderRadius: '0',
      },
    },
    NonSingleBody: {
      selectionInfo: {
        fontSize: fontSizes.sm,
        color: white,
      },
    },
    TextPair: {
      row: {
        fontSize: fontSizes.sm,
        color: white,
      },
    },
    SelectionState: {
      root: {
        fontSize: fontSizes.sm,
      },
    },
    SystemType: {
      amount: {
        color: 'inherit',
        paddingLeft: margins.sm,
      },
    },
    Receipt: {
      root: {
        background: background1,
      },
      controls: {
        background: 'none',
      },
      titleContainer: {
        fontSize: fontSizes.sm,
        padding: margins.xs,
        background: primary,
      },
    },
    ReceiptSelectionAmounts: {
      root: {
        margin: 0,
        borderRadius: 0,
      },
      label: {
        color: text2,
        fontSize: fontSizes.sm,
        marginBottom: margins.md,
      },
      value: {
        color: white,
      },
    },
    SingleSelectionStaticInfo: {
      root: {
        fontSize: fontSizes.sm,
        color: white,
      },
      priceDescription: {
        fontWeight: 'bold',
      },
      outcomePrice: {
        color: text2,
      },
    },
    ReceiptNonSingleSelection: {
      systemTitle: {
        color: white,
      },
    },
    ShareBets: {
      message: {
        '&.shown': {
          color: primary,
        },
      },
    },
  };
};
