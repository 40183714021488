// @flow
import { type JSSThemeDefaultFormType } from '../JSSThemeDefaultForm';

export const JSSThemeForm61400: JSSThemeDefaultFormType = (themeVars) => {
  const {
    colors: {
      colorTypes: { text2, shadow, border1, background4, background6, border2 },
    },
    borderRadius,
  } = themeVars;
  return {
    Input: {
      root: {
        borderColor: 'transparent',
        color: text2,
        background: shadow,
        '&::placeholder': {
          color: border1,
        },
        '&:-webkit-autofill': {
          '-webkit-box-shadow': `0 0 0 30px ${border2} inset !important`,
          borderColor: border2,
          '-webkit-text-fill-color': `${text2} !important`,
        },
      },
      betslipInput: {
        height: '30px',
        lineHeight: '30px',
        border: 'none',
        borderRadius: borderRadius.md,
        color: background6,
        backgroundColor: '#283457',
        '&::placeholder': {
          color: background4,
        },
      },
    },
  };
};
