// @flow
import { type JSSThemeDefaultAuthFormType } from '../JSSThemeDefaultAuthForm';

export const JSSThemeAuthForm61400: JSSThemeDefaultAuthFormType = (
  themeVars
) => {
  const {
    margins,
    borderRadius,
    colors: {
      colorTypes: { background5 },
    },
  } = themeVars;
  return {
    LoginFormDesktop: {
      popUpWrapper: {
        background: '#283457',
        boxShadow: '0 5px 20px rgba(0, 0, 0, 0.8)',
        '&:before': {
          background: '#283457',
        },
      },
    },
    AuthFormDesktop: {
      depositWrap: {
        padding: [margins.xs, margins.md],
        background: background5,
        borderRadius: borderRadius.md,
        marginRight: margins.xs,
      },
    },
  };
};
