// @flow
import { type JSSThemeDefaultDropdownMenuType } from '../JSSThemeDefaultDropdownMenu';

export const JSSThemeDropdownMenu61400: JSSThemeDefaultDropdownMenuType = (
  themeVars
) => {
  return {
    dropdownMobile: {
      selectorWrapMobileFlags: {
        display: 'flex',
      },
      selectorWrapMobile: {
        display: 'none',
      },
    },
  };
};
