// @flow
import { type JSSThemeDefaultBalanceType } from '../JSSThemeDefaultBalance';

export const JSSThemeBalance61400: JSSThemeDefaultBalanceType = (themeVars) => {
  const {
    margins,
    borderRadius,
    colors: {
      colorCombinations: { whitePrimary, whiteBg1 },
      colorTypes: {
        background5,
        white,
        background1,
        shadow,
        primary,
        background6,
      },
    },
  } = themeVars;
  return {
    BalanceComponent: {
      titleContent: {
        padding: [margins.xs, margins.md],
        background: background5,
        borderRadius: borderRadius.md,
        marginRight: margins.xs,
        color: background6,
      },
      userMenuWrap: {
        padding: 0,
        background: '#283457',
        '&:before': {
          background: '#283457',
        },
      },
      accountMenuWrap: {
        borderBottom: `1px solid ${shadow}`,
        borderTop: `1px solid ${shadow}`,
      },
      balanceWrap: {
        display: 'flex',
        justifyContent: 'center',
        padding: [margins.md, 0],
      },
      logoutWrap: {
        color: white,
        fontWeight: 'bold',
        padding: [margins.md, margins.xm],
        '&:hover': {
          opacity: 0.7,
        },
      },
    },
    UsernameCompanent: {
      userName: {
        paddingTop: margins.md,
        color: white,
        opacity: 0.5,
        textAlign: 'center',
      },
      accountNumber: {
        color: white,
        opacity: 0.5,
        textAlign: 'center',
      },
    },
    Bonus: {
      bonusWrap: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        flex: 1,
      },
      bonusTitle: {
        color: white,
      },
      bonusValue: {
        color: primary,
      },
      bonusWrapMobile: {
        display: 'flex',
        alignItems: 'center',
      },
      bonusTitleMobile: {
        fontWeight: 300,
      },
      bonusValueMobile: {
        color: primary,
        fontWeight: 'bold',
        marginLeft: margins.md,
      },
    },
    AccountPageMenu: {
      userMenuItem: {
        padding: [margins.md, margins.xm],
        color: white,
        '&:hover': {
          ...whiteBg1,
        },
      },
    },
    AccountPageMenuDesktop: {
      userMenu: {
        height: '100%',
        background: background1,
      },
      userMenuItem: {
        color: white,
        padding: [margins.xm, margins.lg],
        '&.active, &:hover': {
          ...whitePrimary,
        },
      },
    },
  };
};
