// @flow
import { type JSSThemeDefaultBonusesType } from '../JSSThemeDefaultBonuses';

export const JSSThemeBonuses61400: JSSThemeDefaultBonusesType = (themeVars) => {
  const {
    margins,
    colors: {
      colorTypes: { neutral1, background3, background6, primary },
    },
  } = themeVars;
  return {
    BonusesTabs: {
      tabsItemDesktop: {
        color: background6,
        backgroundColor: '#283457',
        '&:hover, &.active': {
          color: background6,
          backgroundColor: primary,
        },
      },
      tabsItemMobile: {
        color: background6,
        backgroundColor: '#283457',
        borderRight: `1px solid ${neutral1}`,
        '&.active': {
          color: background6,
          backgroundColor: primary,
        },
      },
      bonusContainer: {
        background: 'transparent',
        border: 'none',
        padding: [margins.md, 0],
      },
      tabsContentDesktop: {
        padding: [margins.md, 0],
      },
    },
    Bonuses: {
      root: {
        background: background3,
        border: 'none',
      },
    },
  };
};
