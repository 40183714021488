// @flow
import { type JSSThemeDefaultLiveType } from '../JSSThemeDefaultLive';

export const JSSThemeLive61400: JSSThemeDefaultLiveType = (themeVars) => {
  const {
    colors: {
      colorTypes: { white },
    },
  } = themeVars;
  return {
    LiveMenuEvent: {
      LiveEventRanked: {
        background: '#283457',
      },
      LiveEventRankedDesktop: {
        background: '#283457 !important',
      },
      LiveIcon: {
        fill: white,
      },
    },
    LiveMenuEventHeading: {
      LiveIcon: {
        fill: white,
      },
    },
    LiveMenuRankedEvent: {
      LiveIcon: {
        fill: white,
      },
    },
  };
};
