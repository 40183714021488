// @flow
import { type JSSThemeDefaultCasinoType } from '../JSSThemeDefaultCasino';
import { applyOnlyMobile, applyOnlyDesktop } from '../jss-helpers';

export const JSSThemeCasino61400: JSSThemeDefaultCasinoType = (themeVars) => {
  const {
    margins,
    colors: {
      colorTypes: { white, background1, background6, text2 },
      colorCombinations: { whiteAccent, whiteText3 },
    },
  } = themeVars;
  return {
    CasinoGameCategories: {
      gameCategories: {
        background: background1,
        position: 'static',
        overflowX: 'auto',
        height: 'auto',
      },
      menuItem: {
        padding: '6px 14px',
        color: background6,
        borderRight: 'none',
        '&.active, &:hover': {
          ...whiteAccent,
        },
        ...applyOnlyDesktop({
          '&:hover': {
            ...whiteAccent,
          },
        }),
        ...applyOnlyMobile({
          '&:hover, &:active': {
            color: white,
          },
        }),
      },
      iconChevron: {
        marginLeft: margins.xs,
        '&.active': {
          transform: 'rotateZ(90deg) translateX(1px) translateY(-2px)',
        },
      },
    },
    SearchBox: {
      inputWrap: {
        display: 'flex',
        alignItems: 'center',
      },
      inputSearch: {
        height: 32,

        borderBottom: '1px solid rgba(250,250,250,0.3)',
        '&::placeholder': {
          opacity: 0.5,
        },
      },
      iconSearch: {
        opacity: 0.5,
      },
      searchResult: {
        padding: [0, margins.md],
      },
    },
    CasinoContainer: {
      providersFiltersWrap: {
        background: background1,
      },
      providersFilters: {
        '&.active': {
          ...whiteText3,
          opacity: 1,
          transition: 'all 0.2s ease',
        },
        ...applyOnlyDesktop({
          '&:hover': {
            ...whiteText3,
            opacity: 1,
            transition: 'all 0.2s ease',
          },
        }),
      },
    },
    CasinoGameLink: {
      rootReal: {
        borderRadius: 8,
      },
      rootFun: {
        borderRadius: 8,
        background: text2,
        color: '#B52431',
        '&.active, &:hover': {
          color: '#B52431',
          background: background6,
        },
      },
    },
  };
};
