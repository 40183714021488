// @flow
import {
  type JSSThemeShapeType,
  type JSSThemeVariablesShapeType,
  type ColorTypes,
  getColorCombinations,
  type GetJSSThemePatternsType,
  colorTypes,
} from '../JSSThemeDefault';
import { applyOnlyDesktop, getButtonColors } from '../jss-helpers';
import { JSSThemeBetslip as betslip } from './JSSThemeBetslip61400';
import { JSSThemeHeaderMenu61400 } from './JSSThemeHeaderMenu61400';
import { JSSThemeMyBets61400 } from './JSSThemeMyBets61400';
import { JSSThemeBannerItems61400 } from './JSSThemeBannerItems61400';
import { JSSThemeSportsMenu61400 } from './JSSThemeSportsMenu61400';
import { JSSThemeForm61400 } from './JSSThemeForm61400';
import { JSSThemeTooltip61400 } from './JSSThemeTooltip61400';
import { JSSThemeBonuses61400 } from './JSSThemeBonuses61400';
import { JSSThemeHeaderMobile61400 } from './JSSThemeHeaderMobile61400';
import { JSSThemeSearch61400 } from './JSSThemeSearch61400';
import { JSSThemeEventSlider61400 } from './JSSThemeEventSlider61400';
import { JSSThemeOutcomes61400 } from './JSSThemeOutcomes61400';
import { JSSThemeCoupons61400 } from './JSSThemeCoupons61400';
import { JSSThemeHeader61400 } from './JSSThemeHeader61400';
import { JSSThemeBalance61400 } from './JSSThemeBalance61400';
import { JSSThemeAuthForm61400 } from './JSSThemeAuthForm61400';
import { JSSThemePopularLeagues61400 } from './JSSThemePopularLeagues61400';
import { JSSThemeAccountSection61400 } from './JSSThemeAccountSection61400';
import { JSSTheme60500Resets } from './JSSTheme60500Resets';
import { JSSThemeLive61400 } from './JSSThemeLive61400';
import { JSSThemeCasino61400 } from './JSSThemeCasino61400';
import { JSSThemePromotions61400 } from './JSSThemePromotions61400';
import { JSSThemeSitemap61400 } from './JSSThemeSitemap61400';
import { JSSThemeAffiliates61400 } from './JSSThemeAffiliates61400';
import { JSSThemeDropdownMenu61400 } from './JSSThemeDropdownMenu61400';

export const colorTypes60500: ColorTypes = {
  ...colorTypes,
  primary: '#F00C01',
  text1: '#494949',
  text2: '#bfbfbf',
  text3: '#203552',
  text4: 'rgba(255, 255, 255, 0.5)',
  text5: '',
  background1: '#0C1221',
  background2: '#F00C01',
  background3: '#112840',
  background4: '#999',
  background5: '#2B456B',
  background6: '#D9D9D9', //new color
  shadow: 'rgba(0, 0, 0, 0.4)',
  accent: '#F00C01',
  success: '#21a22d',
  error: '#ff3434',
  error2: '#ff3434',
  error3: '#ff3434',
  warning: 'orange',
  inform: '#830202',
  neutral1: '#2B456B',
  neutral2: '#5c0101',
  neutral3: '',
  neutral4: '#1f3d63', //available for a new color
  border1: '#545e66',
  border2: '#0d1f2d',
};

const pick = ({
  primaryWhite,
  whitePrimary,
  text2Primary,
  whiteBg1,
  whiteBg2,
  whiteBg3,
  whiteSuccess,
  whiteAccent,
  whiteInform,
  whiteNeutral2,
  text2Neutral1,
  text2Bg2,
  whiteError,
  whiteNeutral1,
  text1Bg4,
  primaryBg1,
  text3White,
  text2Bg3,
  whiteText3,
}) => ({
  primaryWhite,
  whitePrimary,
  text2Primary,
  whiteBg1,
  whiteBg2,
  whiteBg3,
  whiteSuccess,
  whiteAccent,
  whiteInform,
  whiteNeutral2,
  text2Neutral1,
  text2Bg2,
  whiteError,
  whiteNeutral1,
  text1Bg4,
  primaryBg1,
  text3White,
  text2Bg3,
  whiteText3,
});

export const colorCombinations60500 = getColorCombinations(
  colorTypes60500,
  pick
);

export const JSSThemeVariables60500: JSSThemeVariablesShapeType = {
  colors: {
    colorTypes: colorTypes60500,
    colorCombinations: colorCombinations60500,
  },
  fonts: {
    default: {
      name: 'osg-ubuntu',
      fontFamily: 'osg-ubuntu, Helvetica Neue, Helvetica, Arial, sans-serif',
      config: [
        {
          path: 'ubuntu/ubuntu-regular-webfont',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        {
          path: 'ubuntu/ubuntu-bold-webfont',
          fontWeight: 'bold',
          fontStyle: 'normal',
        },
      ],
    },
  },
};

export const JSSTheme61400: JSSThemeShapeType = (themeVars) => {
  const {
    colors: {
      colorCombinations: { whiteBg1, whiteNeutral1, text4 },
      colorTypes: {
        background1,
        background3,
        primary,
        text2,
        black,
        white,
        neutral1,
        background6,
      },
    },
    fontSizes,
    margins,
    borderRadius,
  } = themeVars;
  return {
    Heading: {
      common: {
        color: text4,
        padding: [margins.md, margins.xm, '0'],
      },
    },
    Geoblock: {
      logoGeoblock: {
        background: background1,
      },
      textGeoblock: {
        color: white,
      },
    },
    mybets: JSSThemeMyBets61400(themeVars),
    header: JSSThemeHeader61400(themeVars),
    headerMenu: JSSThemeHeaderMenu61400(themeVars),
    Button: {
      root: {
        lineHeight: '26px',
      },
      default: getButtonColors(whiteNeutral1),
      primary: {
        color: background6,
        backgroundColor: primary,
        borderRadius: 8,
      },
      secondary: getButtonColors(whiteNeutral1),
      accent: {
        color: '#B52431',
        backgroundColor: background6,
        borderRadius: 8,
      },
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
      linkSecondary: {
        color: text2,
        ...applyOnlyDesktop({
          '&:hover': {
            color: primary,
          },
        }),
      },
      linkAccent: {
        color: primary,
        ...applyOnlyDesktop({
          '&:hover': {
            color: primary,
            opacity: 0.7,
          },
        }),
      },
    },
    betslip: betslip(themeVars),
    LiveCalendar: {
      h2: {
        ...whiteBg1,
        fontWeight: 'bold',
        padding: [0, margins.md],
      },
      dateTitle: {
        background: background3,
        borderBottom: `1px solid ${background1}`,
        padding: [margins.sm, margins.md],
      },
      dateEvent: {
        background: background1,
        borderBottom: `1px solid ${background3}`,
        padding: [margins.sm, margins.md],
        '&:hover': {
          background: background3,
        },
      },
    },
    IconCircle: {
      root: {
        background: primary,
      },
    },
    NoBetsMessage: {
      root: {
        background: background1,
      },
    },
    BetslipMyBetsPanelTitle: {
      heading: {
        boxShadow: `inset 0px -1px ${black}`,
        background: 'transparent',
        color: background6,
      },
      title: {
        lineHeight: `${margins.xl}px`,
        fontWeight: 'normal',
      },
    },
    BetslipMyBetsStakePotentialTotalUI: {
      stakeTitle: {
        fontWeight: 'normal',
        marginBottom: margins.md,
        marginTop: 0,
        fontSize: fontSizes.sm,
      },
      money: {
        fontSize: fontSizes.md,
        color: white,
      },
      stakePotentialWrapper: {
        marginTop: margins.xs,
        marginBottom: margins.sm,
        background: neutral1,
        padding: [margins.xs, margins.md],
        borderTop: `none`,
        borderRadius: borderRadius.md,
      },
      stakePotential: {
        color: text2,
      },
    },
    BetslipMyBetsPanelUI: {
      panel: {
        ...whiteBg1,
        fontSize: fontSizes.sm,
        margin: 0,
      },
    },
    banners: JSSThemeBannerItems61400(themeVars),
    sportsMenu: JSSThemeSportsMenu61400(themeVars),
    form: JSSThemeForm61400(themeVars),
    tooltip: JSSThemeTooltip61400(themeVars),
    bonuses: JSSThemeBonuses61400(themeVars),
    headerMobile: JSSThemeHeaderMobile61400(themeVars),
    search: JSSThemeSearch61400(themeVars),
    eventSlider: JSSThemeEventSlider61400(themeVars),
    outcomes: JSSThemeOutcomes61400(themeVars),
    coupons: JSSThemeCoupons61400(themeVars),
    balance: JSSThemeBalance61400(themeVars),
    live: JSSThemeLive61400(themeVars),
    authForm: JSSThemeAuthForm61400(themeVars),
    popularLeagues: JSSThemePopularLeagues61400(themeVars),
    accountSection: JSSThemeAccountSection61400(themeVars),
    promotions: JSSThemePromotions61400(themeVars),
    sitemap: JSSThemeSitemap61400(themeVars),
    resets: JSSTheme60500Resets(themeVars),
    affiliates: JSSThemeAffiliates61400(themeVars),
    casino: JSSThemeCasino61400(themeVars),
    dropdownMenu: JSSThemeDropdownMenu61400(themeVars),
    PageNotFound: {
      pageNotFoundRoot: {
        color: white,
        background:
          'url(https://cdn.tornadobet.com/cms/uploads/default/tornado-bg.png)',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
      },
    },
  };
};

export const getJSSThemePatterns60500: GetJSSThemePatternsType = (
  themeVars
) => {
  const {
    colors: {
      colorCombinations: { text2Bg2, whitePrimary, whiteAccent },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    buttons: {
      primary: getButtonColors(whiteAccent),
      secondary: getButtonColors(text2Bg2),
      accent: getButtonColors(whitePrimary),
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
    },
  };
};
